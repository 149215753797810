/**
 * Timetastic
 * Features Quotes component
 */
import React from "react"
import PropTypes from "prop-types"

// Components
import FeaturesQuote from "./featuresQuote"

// Styles
import "./featuresQuotes.scss"

const FeaturesQuotes = ({ quotes }) => {
  return (
    <section className="c-section c-features-quotes">
      <div className="u-inner u-inner--l">
        {quotes &&
          quotes.map((quote, index) => (
            <FeaturesQuote
              avatar={quote.avatar}
              company={quote.company}
              key={`quote-${index}`}
              name={quote.name}
              quote={quote.quote}
            />
          ))}
      </div>
    </section>
  )
}

FeaturesQuotes.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default FeaturesQuotes
