/**
 * Timetastic
 * Features CrossLink component
 */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const FeaturesCrossLink = ({ description, link, title }) => {
  const icon = title.toLowerCase().replace(/ /g, "-")

  return (
    <div className="c-features-cross-link">
      <div className="c-features-cross-link__head">
        <img
          loading="lazy"
          alt={`${title}`}
          className="c-features-cross-link__image"
          src={`/images/features/cross-links/${icon}-link@2x.png`}
        />
      </div>
      <div className="c-features-cross-link__body">
        {/* <h3 className="c-features-cross-link__title  font-m">{title}</h3> */}
        <p>{description}</p>
        <p>
          <Link
            className="c-features-cross-link__link u-em-link u-em-link--light"
            to={link}
          >
            <span>{title}</span>
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
                fill="currentColor"
              />
            </svg>
          </Link>
        </p>
      </div>
    </div>
  )
}

FeaturesCrossLink.propTypes = {
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

// FeaturesCrossLink.defaultProps = {

// }

export default FeaturesCrossLink
