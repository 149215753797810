/**
 * Timetastic
 * Features CrossLinks component
 */
import React from "react"
import PropTypes from "prop-types"

// Components
import FeaturesCrossLink from "./featuresCrossLink"

// Styles
import "./featuresCrossLinks.scss"

const FeaturesCrossLinks = ({ hidden }) => {
  return (
    <section className="c-section u-text-centre c-features-cross-links-container">
      <div className="u-inner u-inner--l">
        <h2 className="h h2 c-features-cross-links-title">
          Your mission control for{" "}
          <span className="u-bgcolor-gradient u-bgcolor-gradient-text">managing absence</span>
        </h2>
        <p className="font-l c-features-cross-links-subtitle">
          Every time-saving, pain-reducing, straightforward feature you’ll need.
        </p>

        <div
          className={`c-features-cross-links${!hidden ? " c-features-cross-links--all" : ""
            }`}
        >
          {hidden !== "Slack Tools" && (
            <FeaturesCrossLink
              description="Get a daily update of who's off work, right in Slack."
              link="/features/slack-tools"
              title="Slack Tools"
            />
          )}
          {hidden !== "Calendar Connections" && (
            <FeaturesCrossLink
              description="View team absences in your regular calendar."
              link="/features/calendar-connections"
              title="Calendar Connections"
            />
          )}
          {hidden !== "Email Reports" && (
            <FeaturesCrossLink
              description="Daily and weekly reports, delivered to your inbox."
              link="/features/email-reports"
              title="Email Reports"
            />
          )}
          {hidden !== "Leave Types" && (
            <FeaturesCrossLink
              description="Book, track and record all types of absence."
              link="/features/leave-types"
              title="Leave Types"
            />
          )}
        </div>
      </div>
    </section>
  )
}

FeaturesCrossLinks.defaultProps = {
  hidden: null,
}

FeaturesCrossLinks.propTypes = {
  hidden: PropTypes.string,
}

export default FeaturesCrossLinks
