/**
 * Timetastic
 * Features > Calendar Connections page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import FeaturesCrossLinks from "src/components/featuresCrossLinks"
import FeaturesQuotes from "src/components/featuresQuotes"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SignupButton from "src/components/signupButton"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/features.scss"

// SEO
const title = "Outlook & Google calendar leave management"
const description =
  "Connect Google calendar or Outlook to Timetastic and see your time off work requests feed directly into your calendar."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} useTitleTemplate={false} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-features-main">
          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className="c-features-intro is-unanimated">
                <div className="c-features-intro__description">
                  <h1 className="h h2 c-features-intro__title">
                    Calendar Connections
                  </h1>
                  <p className="c-features-intro__summary">
                    Connect Timetastic up to your regular calendar and all your
                    absence bookings will automatically feed in.
                  </p>
                  <p>
                    On desktop or mobile - your calendars will always be in-sync
                    and bang up to date.
                  </p>
                  <p>

                    <SignupButton buttonCopy="Start a free trial today" trackCtaLabel="FeaturesSignUp" />

                  </p>
                </div>
                <div className="c-features-intro__media-cc">
                  <div>
                    <img
                      loading="eager"
                      alt="Google Calendar"
                      className="c-features-intro__image"
                      src="/images/google-calendar-icon.svg"
                    />
                    <img
                      loading="eager"
                      alt="Check"
                      className="c-features-intro__check"
                      height="48"
                      src="/images/features/calendar-connections/tick-icon.svg"
                      width="48"
                    />
                  </div>
                  <div>
                    <img
                      loading="eager"
                      alt="Outlook Calendar"
                      className="c-features-intro__image"
                      src="/images/outlook-icon.svg"
                    />
                    <img
                      loading="eager"
                      alt="Check"
                      className="c-features-intro__check"
                      height="48"
                      src="/images/features/calendar-connections/tick-icon.svg"
                      width="48"
                    />
                  </div>
                  <div>
                    <img
                      loading="eager"
                      alt="Apple Calendar"
                      className="c-features-intro__image"
                      src="/images/apple-calendar-icon.svg"
                    />
                    <img
                      loading="eager"
                      alt="Check"
                      className="c-features-intro__check"
                      height="48"
                      src="/images/features/calendar-connections/tick-icon.svg"
                      width="48"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <FeaturesQuotes
            quotes={[
              {
                avatar:
                  "/images/features/calendar-connections/garth-vladislavich-evolve.jpg",
                company: "Evolve Technologies",
                name: "Garth Vladislavich",
                quote:
                  "Calendar sharing is wonderful, it shows us at a glance who is out of the office.",
              },
              {
                avatar:
                  "/images/features/calendar-connections/mark-stringer-ahoy.jpg",
                company: "Ahoy",
                name: "Mark Stringer",
                quote:
                  "I love how I can use it in conjunction with my phone calendar and see who else is off.",
              },
            ]}
          />

          <section className="c-section c-features-xyz">
            <div className="u-inner u-inner--l">
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="eager"
                    alt="Outlook Calendar"
                    className="c-features-grid__image"
                    src="/images/features/calendar-connections/outlook-calendar.png"
                    width={539}
                    height={304}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Office 365 / Outlook.com
                  </h2>
                  <p className="c-features-grid__title">
                    Link to Microsoft’s cloud based calendars for instant
                    updates
                  </p>
                  <p>
                    Once you’re plugged into Outlook.com or Office 365 your
                    personal bookings in Timetastic are pushed instantly into
                    your calendar, lightning fast.
                  </p>
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Google Calendar
                  </h2>
                  <p className="c-features-grid__title">
                    Hook up to Google Calendar for split-second updates
                  </p>
                  <p>
                    Connecting to Google Calendar will get your personal
                    Timetastic bookings pushed directly into your calendar,
                    you’ll always be bang up to date.
                  </p>
                </div>
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Google Calendar"
                    className="c-features-grid__image"
                    src="/images/features/calendar-connections/google-calendar.png"
                    width={540}
                    height={304}
                  />
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Calendar Subscriptions"
                    className="c-features-grid__image"
                    src="/images/features/calendar-connections/calendar-subscriptions.png"
                    width={540}
                    height={311}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Calendar Subscriptions
                  </h2>
                  <p className="c-features-grid__title">
                    Subscribe to a full team or company calendar
                  </p>
                  <p>
                    Calendar feeds give you ultimate flexibility. So you can get
                    full team or organisation absences into any calendar -
                    Microsoft, Apple, Google - anything that can accept an iCal
                    or Webcal feed.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <FeaturesCrossLinks hidden="Calendar Connections" />

          <FreeTrialAlt
            body="If you’re still wondering about something in Timetastic, or need to see how something works. Just shout, we’re here to help."
            buttonCopy="Start free trial today"
            title="If you’re ready, give it a try now"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default;
