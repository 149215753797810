/**
 * Timetastic
 * Features Quote component
 */
import React from "react"
import PropTypes from "prop-types"

const FeaturesQuote = ({ avatar, company, name, quote }) => {
  return (
    <div className="c-features-quote">
      <div className="c-features-quote__head">
        <p>{quote}</p>
      </div>
      <div className="c-features-quote__body">
        <img loading="lazy" alt={name} className="c-features-quote__avatar" src={avatar} width={64} height={64} />
        <div className="c-features-quote__person">
          {name}
          <br />
          {company}
        </div>
      </div>
    </div>
  )
}

FeaturesQuote.propTypes = {
  avatar: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
}

export default FeaturesQuote
